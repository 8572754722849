// export const URL = 'http://localhost:5000/api/user'
// export const DOBAURL = 'http://localhost:5000/api/doba'
// export const MEESHOURL = 'http://localhost:5000/api/meesho'
// export const BLOODURL = 'http://localhost:5000/api/blood'
// export const CJURL = 'http://localhost:5000/api/cj'
// export const BAAPURL = 'http://localhost:5000/api/baap'
// export const PERCENTAGEURL = 'http://localhost:5000/api/percentage'
// export const ROOFTOPURL = 'http://localhost:5000/api/rooftop';
// export const DEODAPURL = 'http://localhost:5000/api/deodap'
// export const GENERALURL = 'http://localhost:5000/api/general'
// export const PAYMENTURL = 'http://localhost:5000/api/order'
// export const GlobalUserURL = "http://localhost:5000/api/globalUser"
// export const COUPONURL = "http://localhost:5000/api/coupons"

// render online api
export const URL = 'https://merciworld.onrender.com/api/user'
export const DOBAURL = 'https://merciworld.onrender.com/api/doba'
export const MEESHOURL = 'https://merciworld.onrender.com/api/meesho'
export const BLOODURL = 'https://merciworld.onrender.com/api/blood'
export const CJURL = 'https://merciworld.onrender.com/api/cj'
export const BAAPURL = 'https://merciworld.onrender.com/api/baap'
export const PERCENTAGEURL = 'https://merciworld.onrender.com/api/percentage'
export const ROOFTOPURL = 'https://merciworld.onrender.com/api/rooftop'
export const DEODAPURL = 'https://merciworld.onrender.com/api/deodap'
export const GENERALURL = 'https://merciworld.onrender.com/api/general'
export const PAYMENTURL = 'https://merciworld.onrender.com/api/order'
export const GlobalUserURL = "https://merciworld.onrender.com/api/globalUser"
export const COUPONURL = "https://merciworld.onrender.com/api/coupons"