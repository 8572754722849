import axios from "axios";
import { PAYMENTURL } from "../constants/constants";

export const paymentMethod = async (options) =>{
    const result = await axios.post(`${PAYMENTURL}/payment`,options);
    return result.data;
}

export const paymentVerfication = async (options) =>{
    const result = await axios.post(`${PAYMENTURL}/paymentValidate`,options);
    return result.data;
}

export const createOrder = async (data)  => {
    const result = await axios.post(`${PAYMENTURL}/purchase`,data);
    return result.data;
}

export const getOrder = async (data)  => {
    const result = await axios.post(`${PAYMENTURL}/getorders`,{userId: data});
    return result.data;
}

export const cancelOrder = async (ORID,spuid)  => {
    const result = await axios.post(`${PAYMENTURL}/cancelOrder`,{ORID: ORID, spuid: spuid});
    return result.data;
  }

  export const createShopOrder = async (data)  => {
    const result = await axios.post(`${PAYMENTURL}/shopPurchase`,data);
    return result.data;
}

export const getCommision = async (level, refer)  => {
    const result = await axios.get(`${PAYMENTURL}/commission/${level}/${refer}`);
    return result.data;
}

export const createMonthlyOrder = async (data)  => {
    const result = await axios.post(`${PAYMENTURL}/monthlyPurchase`,data);
    return result.data;
}
