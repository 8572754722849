import axios  from "axios";
import { GENERALURL, GlobalUserURL, PAYMENTURL } from '../constants/constants';

export const getContact = async () => {
    const validCheck = await axios
      .get(`${GENERALURL}/contactRequest`)
      .then((res) => res.data);
    return validCheck;
};

export const sendContact = async (id,email,subject, data) => {
  const dataVal = {id, email, subject, data}
  const validCheck = await axios
    .post(`${GENERALURL}/contactReply`, dataVal)
    .then((res) => res.data);
  return validCheck;
};

export const getNewsLetter = async () => {
  const validCheck = await axios
    .get(`${GENERALURL}/newsLetter`)
    .then((res) => res.data);
  return validCheck;
};

export const deleteNewsLetter = async (id) => {
  const validCheck = await axios
    .get(`${GENERALURL}/newsLetterdelete/${id}`)
    .then((res) => res.data);
  return validCheck;
};

export const sendNewsLetter = async (subject, htmlMsg) => {
  const sendData = {subject, htmlMsg}
  const validCheck = await axios
    .post(`${GENERALURL}/newsLetterRequest/`, sendData)
    .then((res) => res.data);
  return validCheck;
};

export const getOrder = async ()  => {
  const result = await axios.get(`${PAYMENTURL}/purchaseorders`);
  return result.data;
}

export const updateOrder = async (name, number, ORID,spuid)  => {
  const result = await axios.post(`${PAYMENTURL}/statusupdate`,{name: name, number: number, ORID: ORID, spuid: spuid});
  return result.data;
}

export const getUserbyPhone = async (phone) => {
  try{
  const userData = await axios
    .post(`${GlobalUserURL}/getPhone`, {phone})
    .then((res) => res.data);
    
    return userData;
  } catch (error) {
    return []
  }
};

export const getOrdersById = async (id) => {
  const ordersData = await axios
    .post(`${PAYMENTURL}/ordersbyphone`, {id})
    .then((res) => res.data);
    
    return ordersData;
};
