import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { URL } from '../../constants/constants';

const initialState = {
  loading: false,
  user: {},
  error: ''
};

export const getUserDetails = createAsyncThunk(
  'counter/fetchCount',
  async ( userId ) => {
    let loginres
    if(localStorage.getItem('mercimode') === 'admin'){
      loginres = await axios.get(`${URL}/${userId}`).then( res => res.data);
    } else if (localStorage.getItem('mercimode') === 'shop'){
      loginres = await axios.get(`${URL}/getshopuser/${userId}`).then( res => res.data);
    }
    return loginres;
  }
);


export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.user = {};
        state.error = action.payload;
      })
  },
});

export default userSlice.reducer;
