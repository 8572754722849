import axios from 'axios';
import { CJURL } from '../constants/constants';

export const getCJCategoryList = async () => {
  
    const catelist = await axios.get(`${CJURL}/categories`).then( res => res.data);
    return catelist;

}

export const addProducts = async (productsList)  => {  
    const catelist = await axios.post(`${CJURL}/addProducts/`,{productsList:productsList} ).then( res => res.data);
    return catelist;
}

export const getCJProducts = async (mainselect, subselect, lowselect, currentPage, pageSize)  => {  
    const prodselect = {main_cat: mainselect, sub_cat: subselect, low_cat: lowselect}
    const catelist = await axios.post(`${CJURL}/cjproducts?page=${currentPage}&pageSize=${pageSize}`, prodselect ).then( res => res.data);
    return catelist;

}

export const getProductBySKU = async (sku)  => {  
    const skuid = {sku}
    const catelist = await axios.post(`${CJURL}/cjproduct/`, skuid ).then( res => res.data);
    return catelist;

}

export const setCJPriceUpdate = async (main_cat, sub_cat, low_cat, price)  => {  
    const det = {main_cat, sub_cat, low_cat, price}
    const catelist = await axios.post(`${CJURL}/updatePrice/`, det ).then( res => res.data);
    return catelist;

}

export const setCJUSPriceUpdate = async (main_cat, sub_cat, low_cat, price)  => {  
    const det = {main_cat, sub_cat, low_cat, price}
    const catelist = await axios.post(`${CJURL}/updateUSPrice/`, det ).then( res => res.data);
    return catelist;

}

export const setSingleCJPriceUpdate = async (sku, price, country)  => {  
    const det = {sku, price, country}
    const catelist = await axios.post(`${CJURL}/updateSingle/`, det ).then( res => res.data);
    return catelist;
}

export const setCJHot = async (sku, status)  => {  
    const det = {sku, status}
    const catelist = await axios.post(`${CJURL}/updateHot/`, det ).then( res => res.data);
    return catelist;

}

export const setCJValuable = async (sku, status)  => {  
    const det = {sku, status}
    const catelist = await axios.post(`${CJURL}/updateValuable/`, det ).then( res => res.data);
    return catelist;

}

export const deleteCJProduct = async (sku)  => {  
    const det = {sku}
    const catelist = await axios.post(`${CJURL}/deleteProduct/`, det ).then( res => res.data);
    return catelist;
}