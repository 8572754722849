import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../../assets/avatar.png";
import { useSelector } from "react-redux";
import BalanceButtons from "./BalanceButtons";
import { getCommision } from "../../api/PaymentApi";

const Header = () => {
  const userData = useSelector((state) => state.user.user);
  const [sum, setSum] = useState(0);

  const getCommissionData = async () => {
    if (userData.merci_level) {
      const res = await getCommision(
        userData.merci_level,
        userData.merci_refer_id
      );
      getCommissionSum(res.data, userData.merci_level);
    }
  };

  const getCommissionSum = (dataArray, level) => {
    const commissionKey = `commissionL${level.match(/\d+/)[0]}`;
    const sum = dataArray.reduce((acc, obj) => {
      return acc + (obj[commissionKey] || 0);
    }, 0);

    setSum(sum);
  };

  useEffect(() => {
    getCommissionData();
  }, [userData, sum]);

  const arr = [
    {
      title: "Total Earned",
      value: sum,
    },
    {
      title: "Withdrawn",
      value: 0,
    },
    {
      title: "Balance",
      value: 0,
    },
  ];

  const arrMerch = [
    {
      title: "Total Green Money",
      value: userData?.merci_total || 0,
    },
    {
      title: "Balance",
      value: userData?.merci_green_money || 0,
    },
    {
      title: "Coupons Issued",
      value: userData?.merci_coupons_generated || 0,
    },
    {
      title: "Coupons Active",
      value: userData?.merci_coupons_balance || 0,
    },
    {
      title: "Coupons Closed",
      value: userData?.merci_coupons_used || 0,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("merciadminuser");
    localStorage.removeItem("mercimode");
    window.location.reload();
  };
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div
            className="navbar-brand-box"
            style={{ backgroundColor: "#263D4B" }}
          >
            <Link to="/" className="logo logo-dark">
              <span className="logo-lg">
                <img
                  src="images/merci.png"
                  alt="logo-dark"
                  height="40%"
                  width="100%"
                />
              </span>
            </Link>
          </div>
        </div>

        <div className="d-flex">
          <BalanceButtons
            arr={userData?.merci_level === "Level 5" ? arrMerch : arr}
          />

          <div className="dropdown d-inline-block user-dropdown">
            <button
              type="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="rounded-circle header-profile-user"
                src={userData?.merci_image ? userData?.merci_image : Avatar}
                alt="Header Avatar"
              />
              <span className="d-none d-xl-inline-block ms-1">
                {userData?.merci_level === "Level 5"
                  ? userData?.merci_full_name
                  : `${userData?.merci_last_name} ${userData?.merci_first_name}`}
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to="/userProfile">
                <i className="ri-user-line align-middle me-1"></i> Profile
              </Link>
              <Link className="dropdown-item" to="/userWallet">
                <i className="ri-wallet-2-line align-middle me-1"></i> My Wallet
              </Link>
              <Link className="dropdown-item" to="/changepassword">
                <i className="ri-lock-unlock-line align-middle me-1"></i> Change
                Password
              </Link>
              <div className="dropdown-divider"></div>
              <span
                className="dropdown-item text-danger"
                onClick={handleLogout}
              >
                <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
                Logout
              </span>
            </div>
          </div>

          <div
            className="dropdown d-none d-lg-inline-block ms-1"
            style={{ background: "red" }}
          >
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              data-toggle="fullscreen"
              onClick={handleLogout}
            >
              <i className="fas fa-sign-out-alt" style={{ color: "white" }}></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
