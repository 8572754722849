import axios from 'axios';
import { URL } from '../constants/constants';

export const loginUser = async (phone, password) => {
    const userDetails = { phone: phone, password: password }; 
    const loginres = await axios.post(`${URL}/login`, userDetails ).then( res => res.data);
    return loginres;
}

export const loginShop = async (username, password) => {
    const userDetails = { username: username, password: password }; 
    const loginres = await axios.post(`${URL}/shoplogin`, userDetails ).then( res => res.data);
    return loginres;
}

export const passwordUpdate = async (id, currentPassword, password) => {
    const userDetails = { id: id, currentPassword: currentPassword, password: password }; 
    const loginres = await axios.post(`${URL}/updatePassword`, userDetails ).then( res => res.data);
    return loginres;
}

export const shopPasswordUpdate = async (id, currentPassword, password) => {
    const userDetails = { id: id, currentPassword: currentPassword, password: password }; 
    const loginres = await axios.post(`${URL}/updateShopPassword`, userDetails ).then( res => res.data);
    return loginres;
}
