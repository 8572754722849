import React, { useState } from "react";
import {loginUser, loginShop} from "../../api/loginUser";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    mode: "",
    rememberMe: false,
  });
  const [err, setErr] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res;
    if(formData.mode === 'admin'){
      res = await loginUser(formData.username, formData.password);
    }else if(formData.mode === 'shop'){
      res = await loginShop(formData.username, formData.password);
    }  
    if(res.status === 200){
      localStorage.setItem('merciadminuser', res.user)
      localStorage.setItem('mercimode', formData.mode)
      window.location.href = '/';
    } else {
      setErr(res.message)
    }

  };

  return (
    <div className="auth-body-bg">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-lg-4">
            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div className="w-100">
                <div className="row justify-content-center">
                  <div className="col-lg-9">
                    <div>
                      <div className="text-center">
                        <div>
                          <img
                            src="images/merci.png"
                            alt=""
                            width="100%"
                            height="100%"
                            className="auth-logo logo-dark mx-auto"
                          />
                        </div>

                        <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                        <p className="text-muted">
                          Sign in to continue to Merciplus.
                        </p>
                      </div>
                      <div style={{textAlign: 'center', fontWeight: '700', color: '#C41E3A'}}>{err}</div>
                      <div className="p-2 mt-5">
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3 auth-form-group-custom mb-4">
                            <i className="ri-smartphone-fill auti-custom-input-icon"></i>
                            <label htmlFor="username">Phone Number or Username</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              name="username"
                              value={formData.username}
                              onChange={handleChange}
                              placeholder="Enter Phone Number or Username"
                            />
                          </div>

                          <div className="mb-3 auth-form-group-custom mb-4">
                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                            <label htmlFor="userpassword">Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="userpassword"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              placeholder="Enter password"
                            />
                          </div>

                          <div className="mb-3 auth-form-group-custom mb-4">
                            <i className="ri-user-2-fill auti-custom-input-icon"></i>
                            <label htmlFor="userpassword">Login Mode</label>
                            <select
                            name="mode"
                            onChange={handleChange}
                            className="form-control">
                              <option value="0">Select</option>
                              <option value="admin">Administration</option>
                              <option value="assoc">Associate</option>
                              <option value="shop">Shop Owner</option>
                            </select>
                          </div>

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                              name="rememberMe"
                              checked={formData.rememberMe}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-4 text-center">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <a
                              href="auth-recoverpw.html"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock me-1"></i> Forgot your
                              password?
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="authentication-bg">
              <div className="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
